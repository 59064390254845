html,
body {}

body {}

#root {
    height: 100%;
}

.App {
    text-align: center;
    width: 100%;
    overflow-x: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.text-xxs {
    font-size: 0.65rem;
}

/* extending tailwind line-height utils */

.feature-image {
    max-height: 500px;
}

@media (max-width: 380px) {
    .feature-image {
        max-height: 300px;
    }
}

.schedulist-primary {
    color: #9933cc;
}

.dark .light\:visible {
    opacity: 0;
    display: none;
}

.dark .dark\:visible {
    opacity: 1;
    display: block;
}

.light .dark\:hidden {
    opacity: 1;
    display: block;
}

.light .dark\:visible {
    opacity: 0;
    display: none;
}

@media (prefers-color-scheme: light) {
    body {
        background: #fff;
    }
    .dark\:hidden {
        opacity: 1;
        display: block;
    }
    .dark\:visible {
        opacity: 0;
        display: none;
    }
}

@media (prefers-color-scheme: dark) {
    body {
        background: #000;
    }
    .light\:visible {
        opacity: 0;
        display: none;
    }
    .dark\:visible {
        opacity: 1;
        display: block;
    }
}